<template>
    <gjs-editor :pageId="pageId" :Model="Model">
    </gjs-editor>
</template>

<script>
import GjsEditor from "@/components/GjsEditor";
import Block from "@/models/Block";

export default {
    name: "blocks-show",
    components: {GjsEditor},
    data: function () {
        return {
            Model: Block,
            pageId: null,
        }
    },
    async mounted() {
        this.pageId = this.$route.params.id
    },
}
</script>
